import React, { Component } from 'react'
import PageContents from '../components/pageContents'
import SEO from "../components/seo"
import styles from '../styles/scss/pages/privacy.module.scss'
import cn from "classnames";
import Title from '../components/title'
import Input from '../components/input'
import Select from '../components/select'
import Button from '../components/button'
import ExpandCollapse from '../components/expandCollapse'
import { Formik } from "formik"
import { isArray as _isArray, find as _find } from "lodash"
import { signUp } from "../services/email"
import { sendContact } from "../services/contact"
import { isBrowser } from "../services/util"
import NewLayout from '../layout/newLayout'

export default class privacy extends Component {

  constructor(props) {
    super(props)
    this.state = {
      use1: true,
      use2: false,
      use3: false,
      showExtra: false,
      showModal: false,
      submitted: false,
      selectedReason: null,
      selectedRequest: null,
      selectedInformationType: null,
      selectedResponse: null,
      selectedRequestor: null
    };
    this.changeTab = this.changeTab.bind(this);
    this.toggleUse = this.toggleUse.bind(this);
  }

  requests = [{
    value: 'know-personal-information',
    text: 'Know categories of personal information and/or confirm data processing activities',
    collectProductInfo: true,
    collectRegion: true,
    allowSubmit: true
  }, {
    value: 'obtain-personal-information',
    text: 'Obtain a copy of personal information',
    collectProductInfo: true,
    collectRegion: true,
    allowSubmit: true
  }, {
    value: 'delete-personal-information',
    text: 'Delete Personal Information',
    collectProductInfo: true,
    collectRegion: true,
    allowSubmit: true
  }, {
    value: 'sharing-personal-information',
    text: 'Opt-out of the sale or sharing of your personal information',
    collectProductInfo: true,
    collectRegion: true,
    allowSubmit: true
  }, {
    value: 'limit-personal-information',
    text: 'Limit the use/disclosure of your sensitive personal information',
    collectProductInfo: true,
    collectRegion: true,
    allowSubmit: true
  }, {
    value: 'correct-personal-information',
    text: 'Correct inaccurate personal information',
    collectProductInfo: true,
    collectRegion: true,
    allowSubmit: true
  }]

  informationTypes = [{
    value: 'categories-of-personal-information',
    text: 'Categories of Personal Information',
    collectProductInfo: true,
    collectRegion: true,
    allowSubmit: true
  }, {
    value: 'specific-pieces',
    text: 'Specific Pieces of Personal Information',
    collectProductInfo: true,
    collectRegion: true,
    allowSubmit: true
  }, {
    value: 'both-vategories-and-specific-pieces-of-personal-information',
    text: 'Both Categories and Specific Pieces of Personal Information',
    collectProductInfo: true,
    collectRegion: true,
    allowSubmit: true
  }]

  requestors = [{
    value: 'consumer',
    text: 'Consumer',
    collectProductInfo: true,
    collectRegion: true,
    allowSubmit: true
  }, {
    value: 'authorized-agent',
    text: 'Authorized Agent',
    collectProductInfo: true,
    collectRegion: true,
    allowSubmit: true
  }, {
    value: 'parent-guardian',
    text: 'Parent/Legal Guardian',
    collectProductInfo: true,
    collectRegion: true,
    allowSubmit: true
  }]

  relationShips = [{
    value: 'employee',
    text: 'Employee/Job Applicant',
    collectProductInfo: true,
    collectRegion: true,
    allowSubmit: true
  }, {
    value: 'former-employee',
    text: 'Former Employee/Job Applicant',
    collectProductInfo: true,
    collectRegion: true,
    allowSubmit: true
  }, {
    value: 'customer',
    text: 'Customer',
    collectProductInfo: true,
    collectRegion: true,
    allowSubmit: true
  }, {
    value: 'other',
    text: 'Other',
    collectProductInfo: true,
    collectRegion: true,
    allowSubmit: true
  }]

  handleClickExtraInfo = () => {
    this.setState({
      showExtra: !this.state.showExtra
    })
  }

  toggleUse = (tabActive) => {
    switch (tabActive) {
      case 'use1':
        this.setState({ use1: true, use2: false, use3: false });
        break;
      case 'use3':
        this.setState({ use3: true, use1: false, use2: false });
        break;
      default:
        this.setState({ use1: true, use2: false, use3: false });
    }
  }

  changeTab() {
    switch ((window.location.hash)) {
      case '#privacy-statement':
        this.toggleUse('use1')
        break;
      case '#ccpa-request':
        this.toggleUse('use3')
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        break;
      default:
        const element = document.getElementById(window.location.hash.replace('#', ''));
        const elementPosition = element.getBoundingClientRect().top - document.body.getBoundingClientRect().top;
        window.scrollTo({
          top: elementPosition - 50,
          behavior: 'smooth'
        });
    }
  }

  componentDidMount() {
    if (isBrowser()) {
      window.addEventListener("hashchange", this.changeTab, false);
    }
    if (window.location.hash === '#ccpa-request') {
      this.toggleUse('use3')
    }
  }

  componentWillUnmount() {
    if (isBrowser()) {
      window.removeEventListener("hashchange", this.changeTab, false);
    }
  }

  render() {
    const pageData = this.props.pageContext.page; const { showExtra } = this.state
    const pg = this.props.pageContext.page
    const attached = pg.attachments.filter((att) => {
      return att.slug === 'thank-you-contact'
    })
    var thankyou = null
    if (attached.length > 0) {
      thankyou = attached[0]
    } else {
      throw new Error("thank-you-contact is not attached")
    }
    return (
      <NewLayout>
        <SEO page={pageData} />
        {pageData.seo.title && <h1 style={{ display: 'none' }}>{pageData.seo.title}</h1>}
        <div className='d-flex flex-column flex-fill'>
          <div className={cn(styles.containerColumn)}>
            <div className={cn(styles.containerTabs)}>
              <div className={cn(styles.containerTab)} onClick={() => { this.toggleUse('use1') }}>
                <span className={cn(this.state.use1 ? styles.containerTabActive : styles.containerTab)}>Privacy Statement</span>
              </div>
              <div className={cn(styles.containerTab)} onClick={() => { this.toggleUse('use3') }} >
                <span className={this.state.use3 ? styles.containerTabActive : styles.containerTab}>Privacy Rights Requests</span>
              </div>
            </div>
            <div>
              {this.state.use1 &&
                <PageContents content={[pageData.content[0]]} />
              }
              {this.state.use3 && (
                <div className={cn(styles.formItems, "row")}>
                  <div className="col-sm-8">
                    <Title value='Privacy Rights Requests' />
                    {
                      this.state.submitted ?
                        <PageContents content={thankyou.content} />
                        :
                        <>
                          <Formik
                            initialValues={{
                              firstName: "",
                              lastName: "",
                              email: "",
                              lists: [],
                              addr1: "",
                              addr2: "",
                              zip: "",
                              phone: "",
                              group: "ccpa",
                              reason: "",
                              request: "",
                              informationType: "",
                              response: "",
                              requestor: "",
                              province: "",
                              agentFirstName: "",
                              agentLastName: "",
                              agentEmail: "",
                              agentAddr1: "",
                              agentAddr2: "",
                              agentZip: "",
                              agentPhone: "",
                              agentProvince: "",
                              relationShip: ""
                            }}
                            validate={values => {
                              var errors = []
                              if (values.firstName === "") {
                                errors.push({ field: "firstName", message: "First Name is required" })
                              }
                              if (values.lastName === "") {
                                errors.push({ field: "lastName", message: "Last Name is required" })
                              }
                              if (values.email === "") {
                                errors.push({ field: "email", message: "Email is required" })
                              } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                                errors.push({ field: "email", message: "Invalid email address" })
                              }
                              if (values.phone === "") {
                                errors.push({ field: "phone", message: "Phone is required" })
                              }
                              if (values.relationShip === "") {
                                errors.push({ field: "relationShip", message: "Relationship is required" })
                              }
                              if (values.requestor === "authorized-agent") {
                                if (values.agentFirstName === "") {
                                  errors.push({ field: "agentFirstName", message: "Agent First Name is Required" })
                                }
                                if (values.agentLastName === "") {
                                  errors.push({ field: "agentLastName", message: "Agent Last Name is Required" })
                                }
                                if (values.agentEmail === "") {
                                  errors.push({ field: "agentEmail", message: "Agent Email is Required" })
                                } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                                  errors.push({ field: "email", message: "Invalid Agent Email address" })
                                }
                                if (values.agentPhone === "") {
                                  errors.push({ field: "agentPhone", message: "Agent Phone is required" })
                                }
                              }
                              return errors
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                              this.setState({
                                showModal: true
                              })
                              sendContact(values).then((result) => {
                                if (values.lists.length > 0) {
                                  signUp(values.firstName, values.lastName, values.email, values.lists).then((result) => {
                                    setSubmitting(false)
                                    this.setState({
                                      submitted: true,
                                      showModal: false
                                    }, () => { })
                                  })
                                } else {
                                  setSubmitting(false)
                                  this.setState({
                                    submitted: true,
                                    showModal: false
                                  }, () => { })
                                }
                              }).catch((err) => {
                                console.log('ERROR!')
                                console.log(err)
                                setSubmitting(false)
                                this.setState({
                                  showModal: false
                                })
                              })
                            }}>
                            {(props) => (
                              <form className={styles.form} onSubmit={props.handleSubmit}>
                                <div>
                                  <text className={styles.formSubTitle}>
                                    Depending on where you live and your relationship with us, you may have certain rights as described in
                                    our <a href='/privacy'>Privacy Policy</a> or <a href='https://files.wd40.com/pdf/WD-40+Global+Employee+Privacy+Notice+Mar+26+2024+English.pdf'>Workforce Privacy Notice.</a>
                                  </text>
                                  <div className={styles.row}>
                                    <div className="col-sm-6" style={{ padding: '0px' }}>
                                      <Title className={styles.formSubTitle} value='Requestor' />
                                      <Select
                                        title="Requestor Type*"
                                        titleClassName={styles.selectTitle}
                                        noMargin
                                        className={styles.select}
                                        placeholder='Select a Requestor'
                                        values={this.requestors}
                                        onChange={(e) => {
                                          this.setState({
                                            selectedRequestor: _find(this.requestors, { value: e.value })
                                          }, () => {
                                            props.setFieldValue('requestor', e.value)
                                          })
                                        }}
                                        onBlur={props.handleBlur}
                                        value={props.values.requestor}
                                      />
                                    </div>
                                  </div>
                                  <div className={styles.row}>
                                    <div className="col-sm-6" style={{ padding: '0px', paddingBottom: '50px' }}>
                                      <Title className={styles.formSubTitle} value='Request' />
                                      <Select
                                        title="Type of Request*"
                                        titleClassName={styles.selectTitle}
                                        noMargin
                                        className={styles.select}
                                        placeholder='Select Request'
                                        values={this.requests}
                                        onChange={(e) => {
                                          this.setState({
                                            selectedRequest: _find(this.requests, { value: e.value })
                                          }, () => {
                                            props.setFieldValue('request', e.value)
                                          })
                                        }}
                                        onBlur={props.handleBlur}
                                        value={props.values.request}
                                      />
                                    </div>
                                  </div>
                                  {props.values.request === 'know-personal-information' &&
                                    <div className={styles.row}>
                                      <div className="col-sm-6" style={{ padding: '0px' }}>
                                        <Select
                                          title="What type of information are you requesting?*"
                                          titleClassName={styles.selectTitle}
                                          noMargin
                                          className={styles.select}
                                          placeholder='Select Type'
                                          values={this.informationTypes}
                                          onChange={(e) => {
                                            this.setState({
                                              selectedInformationType: _find(this.informationTypes, { value: e.value })
                                            }, () => {
                                              props.setFieldValue('informationType', e.value)
                                            })
                                          }}
                                          onBlur={props.handleBlur}
                                          value={props.values.informationType}
                                        />
                                      </div>
                                    </div>
                                  }
                                  <Title className={styles.formSubTitle} value="Requestor's Information" />
                                  <text className={styles.formSubTitle}>
                                    Please enter information regarding the person who is making this request so that we may communicate
                                    with you regarding the request.
                                  </text>
                                  {props.values.requestor === 'authorized-agent' && <text className={styles.authorizedText}>
                                    <strong>Authorized Agents:<br /></strong>
                                    Please provide the below information about the consumer on whose behalf you are making this request so that we may verify the consumer’s identity.
                                  </text>
                                  }
                                  <div className={styles.row}>
                                    <Input
                                      name="firstName"
                                      title="First Name*"
                                      placeholder="John"
                                      className={styles.input}
                                      onChange={props.handleChange}
                                      onBlur={props.handleBlur}
                                      value={props.values.firstName}
                                    />
                                    <Input
                                      name="lastName"
                                      title="Last Name*"
                                      placeholder="Doe"
                                      className={styles.input}
                                      onChange={props.handleChange}
                                      onBlur={props.handleBlur}
                                      value={props.values.lastName}
                                    />
                                  </div>
                                  <div className={styles.row} style={{ paddingBottom: '50px' }}>
                                    <Input
                                      name="addr1"
                                      title='Address'
                                      className={styles.input}
                                      placeholder='1234 Main Street'
                                      onChange={props.handleChange}
                                      onBlur={props.handleBlur}
                                      value={props.values.addr1} />
                                    <Input
                                      name="email"
                                      title="Email*"
                                      placeholder="jdoe@email.com"
                                      className={styles.input}
                                      onChange={props.handleChange}
                                      onBlur={props.handleBlur}
                                      value={props.values.email}
                                    />
                                  </div>

                                  <Title className={styles.formSubTitle} value='Consumer Information' />
                                  <text className={styles.formSubTitle}>
                                    Please enter information regarding the person on whose behalf request is being made below so their
                                    identity can be verified.
                                  </text>
                                  {props.values.requestor === 'authorized-agent' && <text className={styles.authorizedText}>
                                    <strong>Authorized Agents:<br /></strong>
                                    Please provide the below information about the consumer on whose behalf you are making this request so that we may verify the consumer’s identity.
                                  </text>
                                  }
                                  <div className={styles.row}>
                                    <Input
                                      name="firstName"
                                      title="First Name*"
                                      placeholder="John"
                                      className={styles.input}
                                      onChange={props.handleChange}
                                      onBlur={props.handleBlur}
                                      value={props.values.firstName}
                                    />
                                    <Input
                                      name="lastName"
                                      title="Last Name*"
                                      placeholder="Doe"
                                      className={styles.input}
                                      onChange={props.handleChange}
                                      onBlur={props.handleBlur}
                                      value={props.values.lastName}
                                    />
                                  </div>
                                  <div className={styles.row}>
                                    <Input
                                      name="addr1"
                                      title='Address'
                                      className={styles.input}
                                      placeholder='1234 Main Street'
                                      onChange={props.handleChange}
                                      onBlur={props.handleBlur}
                                      value={props.values.addr1} />
                                    <Input
                                      phone="true"
                                      name="phone"
                                      title='Phone*'
                                      placeholder="(000) 000-0000"
                                      className={styles.input}
                                      onChange={props.handleChange}
                                      onBlur={props.handleBlur}
                                      value={props.values.phone} />
                                  </div>
                                  <div className={styles.row} style={{ display: 'flex', flexDirection: 'row', padding: '0 7.5px' }}>
                                    <Input
                                      name="email"
                                      title="Email*"
                                      placeholder="jdoe@email.com"
                                      className={styles.input}
                                      onChange={props.handleChange}
                                      onBlur={props.handleBlur}
                                      value={props.values.email}
                                      style={{ flex: 1 }}
                                    />
                                  </div>
                                </div>

                                <div className={styles.row}>
                                  <div className="col-sm-6" style={{ padding: '0px' }}>
                                    <Title className={styles.formSubTitle} value='Relationship' />
                                    <Select
                                      title="Relationship with us*"
                                      titleClassName={styles.selectTitle}
                                      noMargin
                                      className={styles.select}
                                      placeholder='Select a Relationship'
                                      values={this.relationShips}
                                      onChange={(e) => {
                                        this.setState({
                                          selectedRequestor: _find(this.relationShips, { value: e.value })
                                        }, () => {
                                          props.setFieldValue('relationShip', e.value)
                                        })
                                      }}
                                      onBlur={props.handleBlur}
                                      value={props.values.relationShip}
                                    />
                                  </div>
                                </div>

                                {props.values.requestor === 'authorized-agent' &&
                                  <div style={{ paddingTop: '50px' }}>
                                    <text className={styles.authorizedText}>
                                      <strong >Authorized Agents:<br /></strong>
                                      Please fill out the section below with your own information so that we may contact you to help verify the consumer’s identity.
                                    </text>
                                    <div className={cn(styles.rowExpand)}>
                                      <ExpandCollapse className={styles.ccpaCollapse} title='Authorized Agent Personal Information' inverted onClick={this.handleClickExtraInfo.bind(this)} />
                                    </div>
                                    {showExtra && (
                                      <div>
                                        <Title className={styles.formSubTitle} value='Authorized Agent Information' />
                                        <div className={styles.row}>
                                          <Input
                                            name="agentFirstName"
                                            title="First Name*"
                                            placeholder="John"
                                            className={styles.input}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props.values.agentFirstName}
                                          />
                                          <Input
                                            name="agentLastName"
                                            title="Last Name*"
                                            placeholder="Doe"
                                            className={styles.input}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props.values.agentLastName}
                                          />
                                        </div>
                                        <div className={styles.row}>
                                          <Input
                                            name="agentEmail"
                                            title="Email*"
                                            placeholder="jdoe@email.com"
                                            className={styles.input}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props.values.agentEmail}
                                          />
                                          <Input
                                            phone="true"
                                            name="agentPhone"
                                            title='Phone*'
                                            placeholder="(000) 000-0000"
                                            className={styles.input}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props.values.agentPhone} />
                                        </div>
                                        <Title className={styles.formAdressSubTitle} value='Address' />
                                        <div className={styles.row}>
                                          <Input
                                            name="agentAddr1"
                                            title="Address Line 1"
                                            placeholder="1234 Main Street"
                                            className={styles.input}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props.values.agentAddr1}
                                          />
                                          <Input
                                            name="agentAddr2"
                                            title="Address Line 2"
                                            className={styles.input}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props.values.agentAddr2}
                                          />
                                        </div>
                                        <div className={styles.row}>
                                          <Input
                                            name="agentProvince"
                                            title='State/Province'
                                            className={styles.input}
                                            placeholder='State'
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props.values.agentProvince} />
                                          <Input
                                            name="agentZip"
                                            title='Zip/Postal'
                                            className={styles.input}
                                            placeholder='00000'
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props.values.agentZip} />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                }
                                <div className={cn(styles.feedback)}>
                                  <ul>
                                    {_isArray(props.errors) && props.errors.filter((f) => { return props.touched[f.field] }).map((e) => {
                                      return <li key={e.field}>{e.message}</li>
                                    })}
                                  </ul>
                                </div>
                                <Title className={styles.formSubTitle} value='ACKNOWLEDGEMENT' />
                                <text className={styles.formSubTitle}>
                                  By pressing submit, I declare under penalty of perjury I am the person identified above or I am an
                                  authorized agent of the person identified above, and the information I provided is true and
                                  accurate.
                                </text>
                                <Button
                                  text="Submit"
                                  blue
                                  submit
                                  className={styles.submitButton}
                                />
                              </form>
                            )}
                          </Formik>
                        </>
                    }
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {this.state.showModal &&
          <div className={cn(styles.customModal)}>
            <div className={cn(styles.modalContent)}>
              <div>
                <i className={cn('fa', 'fa-clock-o')}></i>
              </div>
              <div>
                <Title yellow>Please wait...</Title>
              </div>
            </div>
          </div>
        }
      </NewLayout>
    );
  }
}